import React, { useState, useEffect } from "react"

import {
  fetchClubList,
  tagCodeToTagName,
  tagCodeToTagType,
  tagCodeToIcon,
  tagCodeToDescription,
  categories,
} from "../utils/clubs"
import instagramIcon from "../img/social/instagram.svg"

const JoinButton = ({ url }) => (
  <a href={url} target="_blank">
    <span className="icon">
      <button class="button is-small is-primary">Join</button>
    </span>
  </a>
)

const InstagramButton = ({ url }) => (
  <a href={url} target="_blank">
    <span className="icon">
      <img src={instagramIcon} alt="Instagram" title="Instagram" />
    </span>
  </a>
)

const Tag = ({ code }) => (
  <>
    {tagCodeToTagName[code] && (
      <span className={`tag mr-1 ${tagCodeToTagType[code]}`}>
        {tagCodeToIcon[code] && (
          <span className="icon mr-1">
            <i className={tagCodeToIcon[code]}></i>
          </span>
        )}
        {tagCodeToTagName[code]}
      </span>
    )}
  </>
)

const Tags = ({ list }) => list.map(code => <Tag code={code} />)

const TagsInfo = () => (
  <div className="content">
    <>
      {Object.keys(tagCodeToDescription).map(code => (
        <div className="mb-1">
          <Tag code={code} /> - {tagCodeToDescription[code]}
        </div>
      ))}
    </>
  </div>
)

const filterByCategory = ({ clubs, category }) => {
  return category === "ALL"
    ? clubs
    : clubs.filter(club => {
        if (club.category === undefined) return
        return club.category.includes(category)
      })
}

const ClubsTable = () => {
  const [clubs, setClubs] = useState([])
  const [currentCategory, setCurrentCategory] = useState("ALL")
  const [currentClubs, setCurrentClubs] = useState([])

  useEffect(async () => {
    const clubs = await fetchClubList()
    setClubs(clubs)
    setCurrentClubs(clubs)
  }, [])

  return (
    <>
      <a href="https://go.vitspot.com/9d051" target="_blank">
        <button class="button is-primary mb-2 mr-2">
          <span class="icon is-small">
            <i class="fas fa-pen"></i>
          </span>
          <span>Update Your Club Info</span>
        </button>
      </a>
      <a href="https://www.instagram.com/vitspot" target="_blank">
        <button class="button is-danger mb-2 mr-2">
          <span class="icon is-small">
            <i class="fab fa-instagram"></i>
          </span>
          <span>Follow our Instagam Page</span>
        </button>
      </a>
      <a href="https://t.me/vitx_all" target="_blank">
        <button class="button is-info mb-2 mr-2">
          <span class="icon is-small">
            <i class="fab fa-telegram-plane"></i>
          </span>
          <span>Join our Telegram Group</span>
        </button>
      </a>
      <div className="tabs">
        <ul>
          {categories.map(({ code, title, icon }) => (
            <li className={currentCategory === code ? "is-active" : ""}>
              <a
                onClick={() => {
                  setCurrentCategory(code)
                  setCurrentClubs(filterByCategory({ clubs, category: code }))
                }}
              >
                <span className="icon is-small">
                  <i className={icon} aria-hidden="true"></i>
                </span>
                <span>{title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Club/Chapter (Ordered A-Z)</th>
              <th className="is-hidden-mobile">About</th>
              <th>Join</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentClubs.map(
              ({
                name,
                tags = [],
                category = [],
                about,
                recruitment,
                instagram,
              }) => (
                <tr>
                  <td>
                    {`${name} `}
                    <Tags list={category} />
                    <Tags list={tags} />
                  </td>
                  <td className="is-hidden-mobile">{about}</td>
                  <td>{recruitment && <JoinButton url={recruitment} />}</td>
                  <td>{instagram && <InstagramButton url={instagram} />}</td>
                </tr>
              )
            )}
            {clubs.length === 0 && <p>Loading...</p>}
          </tbody>
        </table>
        <TagsInfo />
      </div>
    </>
  )
}

export default ClubsTable
