/* eslint-disable no-return-await */
export const fetchClubList = async () => {
  const response = await fetch(`https://services.vitspot.com/clubs/`)
  return await response.json()
}

export const categories = [
  { code: "ALL", title: "All", icon: "fas fa-users" },
  { code: "TECH", title: "Tech", icon: "fas fa-laptop" },
  { code: "CORE", title: "Core", icon: "fas fa-wrench" },
  { code: "NGO", title: "NGO", icon: "fas fa-hands-helping" },
  { code: "ARTS", title: "Arts", icon: "fas fa-palette" },
  { code: "CUL", title: "Cultural", icon: "fas fa-drum" },
  { code: "SAE", title: "SAE Teams", icon: "fas fa-flag-checkered" },
  { code: "TEAM", title: "Other Teams", icon: "fas fa-user-friends" },
  { code: "OTH", title: "Others", icon: "fas fa-ellipsis-h" },
]

export const tagCodeToTagName = {
  EC: `Editor's Choice`,
  FG: `Fast Growing`,
  NEW: `New`,
  FAM: `Popular`,
  EXC: `Exclusive`,
  SP: `Social+`,

  TECH: `Tech`,
  CORE: `Core`,
  NGO: `NGO`,
  ARTS: `Arts`,
  CUL: `Cultural`,
  SAE: `SAE Team`,
  TEAM: `Team`,
}

export const tagCodeToTagType = {
  EC: `is-dark`,
  FG: `is-warning`,
  NEW: `is-success`,
  FAM: `is-info`,
  EXC: `is-danger`,
  SP: `is-warning`,

  TECH: `is-light`,
  CORE: `is-light`,
  NGO: `is-light`,
  ARTS: `is-light`,
  CUL: `is-light`,
  SAE: `is-light`,
  TEAM: `is-light`,
}

export const tagCodeToIcon = {
  EC: `fas fa-award`,
  FG: `fas fa-bolt`,
  NEW: `fas fa-seedling`,
  FAM: `fas fa-star`,
  EXC: `fas fa-angle-double-up`,
  SP: `fas fa-thumbs-up`,

  TECH: `fas fa-laptop`,
  CORE: `fas fa-wrench`,
  NGO: `fas fa-hands-helping`,
  ARTS: `fas fa-palette`,
  CUL: `fas fa-drum`,
  SAE: `fas fa-flag-checkered`,
  TEAM: `fas fa-user-friends`,
}

export const tagCodeToDescription = {
  EC: `Discover the best highly unopinionated clubs in VIT handpicked by VITspot`,
  FG: `Fast Growing from small to big`,
  NEW: `New budding club`,
  FAM: `Well established and renowned club`,
  EXC: `Only one of its kind club`,
  SP: `Clubs with good social media presence`,
}
