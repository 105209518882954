import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ClubsTable from '../components/ClubsTable'

export const ClubsPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section columns">
      <div class="column is-10 is-offset-1">
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          {title}
        </h2>
        <div className="mb-4">
          <a href="https://go.vitspot.com/join" target="_blank">
            <div class="notification is-dark">
              <strong>We're Recruiting!</strong> <br />
              Join VITspot Team
            </div>
          </a>
        </div>
        <PageContent className="content" content={content} />
        <ClubsTable />
      </div>
    </section>
  )
}

ClubsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ClubsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ClubsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ClubsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ClubsPage

export const pageQuery = graphql`
  query ClubsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
